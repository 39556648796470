@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-bold-opentype_1-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-bold-opentype_1-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-book-opentype-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-book-opentype-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-bookitalic-opentype-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-bookitalic-opentype-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-light-opentype-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-light-opentype-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-medium-opentype-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-medium-opentype-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/ufonts.com_apexnew-mediumitalic-opentype-webfont.woff2') format('woff2'),
    url('/assets/fonts/ufonts.com_apexnew-mediumitalic-opentype-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'apex';
  src: url('/assets/fonts/apexnew-bolditalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/apexnew-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'rufina';
  src: url('/assets/fonts/tipotype_rufina.bold.italic-webfont.woff2') format('woff2'),
    url('/assets/fonts/tipotype_rufina.bold.italic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'rufina';
  src: url('/assets/fonts/tipotype_rufina.bold-webfont.woff2') format('woff2'),
    url('/assets/fonts/tipotype_rufina.bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'rufina';
  src: url('/assets/fonts/tipotype_rufina.italic-webfont.woff2') format('woff2'),
    url('/assets/fonts/tipotype_rufina.italic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;

}

@font-face {
  font-family: 'rufina';
  src: url('/assets/fonts/tipotype_rufina-webfont.woff2') format('woff2'),
    url('/assets/fonts/tipotype_rufina-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}